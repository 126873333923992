import { Button, Chip, Grid, Paper } from "@mui/material"
import { configColorList } from "../util/configColorList"
import { useEffect, useState } from "react"
import { getConfigBlobNames } from "./config/BlobUtils"


type SiteChooseProps={
    //  configNameList:string[],
    user:string,
    setSelectedConfig:Function,
    setColorScheme:Function
}

export const SiteChooseView = (props:SiteChooseProps)=>{
    const [configNameList,setConfigNameList] = useState([] as string[])
    // console.log("siteChooseView loaded")
    useEffect(()=>{
        // console.log("useEffect called");
        getConfigBlobNames("config",(data:any)=>setConfigNameList(data))
    },[props.user])


    const handleClick = (configName:string):void=>{
        props.setSelectedConfig(configName);
        props.setColorScheme(configColorList[configNameList.indexOf(configName)]||"#A079F2")
    }

    if(!configNameList.length)return (<></>)
    return(
        <Grid container direction="column" style={{margin:"90px"}}>
            <Grid item>
            <h1 style={{fontWeight:700,fontSize:36}}>Hello, {props.user}!</h1>
            </Grid>
           <Grid item>
                <Grid container direction="row" marginTop="20px">
                    {configNameList.map((name,i)=>(
                        <Paper 
                            key={name}
                            style={{
                                background:configColorList[i],
                                padding:"17px",
                                width:"450px",
                                height:"490px",
                                borderRadius: "10px",
                                margin:"25px",
                                position:"relative"}}
                            >

                            
                            <Chip 
                                label={i === 0 ? "Main site":"Test Site"} 
                                color="primary"
                                size="small"
                                sx={{margin:"20px",boxShadow:2,fontWeight:700}}
                                />
                            <div style={{marginLeft:"20px"}}>
                                <span style={{fontWeight:700,fontSize:20}}>{name}</span>
                            </div>

                            <Button 
                                onClick={()=>{handleClick(name)}} 
                                style={{position:"absolute",bottom:30,right:50,fontWeight:700}}
                                variant="contained">
                                    Edit
                            </Button>
                        </Paper>))}
                </Grid>
            </Grid>
        </Grid>
    )
}