import { useEffect, useState } from 'react';
import './App.css';

import Login from './components/Login'
// import Dashboard from './components/Dashboard';
import SideBar from './components/SideBar'
import ConfigEditor from './components/ConfigEditor'
import CustomAlert from './components/CustomAlert'
import { Grid, Container, CssBaseline,ThemeProvider, createTheme} from '@mui/material'
import { viTheme } from './util/viTheme';
import { SiteChooseView } from './components/SiteChooseView';
// import { getConfigBlobNames, getConfigData } from './components/config/BlobUtils';

export default function App() {
  const [render, setRender] = useState("Choose Site")
  const [state, setState] = useState({
      isLoggedIn: false,
      user: {
        user: "",
        flags: "111" // use context?
      }
  })
  const [configList,setConfigList] = useState([] as string[]);
  const [colorScheme,setColorScheme] = useState("");
  const [selectedConfig,setSelectedConfig] = useState(null);
  const [alert, setAlert] = useState({
    on: false,
    props: {
      open: false,
      type: "",
      message: "",
      handler: () => {},
      duration: 0
    }
  })
  // console.log("loading App()")


  const handleRender = (target: string) => {
    switch(target){
      case("Locked Words"):
        setRender("Locked Words")
        break;
        case("Replacements"):
        setRender("Replacements")
        break;
        case("Postprocess"):
        setRender("Postprocess")
        break;
        default:
          setRender(target)
          break;
    }
  }
  
  const handleUserState = (user: any, isLoggedIn: boolean) => {
    setState({user: user, isLoggedIn: isLoggedIn})
    // handleAlertOpen("THIS IS TEST ALERT", "success", 3500)
  }

  const handleAlertClose = () => {
    setAlert({
      on: false, props: {
        open: false,
        message: "",
        type: "info",
        handler: () => { console.log("typescript is awesome")},
        duration: 0
      }
    })
  }

  const handleAlertOpen = (message: string, type: string, duration: number) => {
    setAlert({ 
      on: true, 
      props: {
        open: true,
        message: message,
        type: type,
        handler: handleAlertClose,
        duration: duration
      }
    })
  }

  return(
    <>
      <ThemeProvider theme={viTheme}>
        <CssBaseline/>
        <Container 
          maxWidth={false}
          disableGutters={true} 
          sx={{padding:"100px",marginTop:"-100px"}}>
            {state.isLoggedIn !== false ? ( 
              <Grid 
                container 
                spacing={0} 
                justifyContent="left" 
                marginLeft={"255px"} 
                wrap="nowrap" 
                sx={{background: "#fafafa",borderRadius: "28px",marginTop: "27px"}}>
                  <SideBar 
                    flags={state.user.flags} 
                    handler={handleRender} 
                    selectedItem={render} 
                    setSelectedItem={setRender}/>
                  {(render === "Choose Site"? 
                    <SiteChooseView 
                      // configNameList={configList} 
                      user={state.user.user} 
                      setSelectedConfig={(item:any)=>{setSelectedConfig(item);setRender("Locked Words")}}
                      setColorScheme={setColorScheme}/>:
                    <ConfigEditor 
                      user={state.user} 
                      alertHandler={handleAlertOpen} 
                      render={render}
                      loadBlobAutomatically={true}
                      colorScheme={colorScheme}
                      selectedConfig={selectedConfig ? selectedConfig:""}
                      />
                      )
                  }
                  {alert.on ? <CustomAlert {...alert.props}/> : null}
              </Grid>
              ):
              <Login setUser={handleUserState} /> }
        </Container>
      </ThemeProvider>
    </>

  )
}