import axios from "../constants"

const handlePurge = (blobName:string,responseHandler:Function=()=>{},alertHandler:Function=()=>{}):any => {
    if (! blobName) {
        alertHandler("Select file", "error", 3000)
        return
    }
    axios.post('/api/purge/', { contentPaths: [blobName] })
    .then(r => {
        responseHandler(r.data)
    })
    .catch(e => { 
        //responseHandler(e.response.data)
        alertHandler(e.response)
    }) 
}

export default handlePurge