import React, { useState, useEffect} from 'react'

import { ListItemText, ListItemButton, ListItem, List } from '@mui/material'

import supportedLanguages from '../supportedLanguages'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons'

interface confProps {
    config: any,
    handler: any
}

const LanguagePicker: React.FC<confProps> = (props) => {

    useEffect(() => {

        if (props.config.translationLanguages) {
            return
        }
            props.config.translationLanguages = []
            props.handler(props.config)
    }, [])

    if (! props.config.translationLanguages) {
        return (
            <>
            loading
            </>
        )
    }
    else {

    const isActive = (language: string) => {
        return props.config.translationLanguages.includes(language)
    }

    const toggleLanguage = (languageCode: string) => {
        if (isActive(languageCode)) {
            props.config.translationLanguages = props.config.translationLanguages.filter((lang: string) => lang !== languageCode)
            props.handler(props.config)
        }
        else {
            props.config.translationLanguages = [...props.config.translationLanguages, languageCode]
            props.handler(props.config)
        }
    }

    const languagePicker = () => {
        let enabled = props.config.translationLanguages.map((langCode: string) => {
            return supportedLanguages.find(language => language.code === langCode)
        })
        let disabled = supportedLanguages.filter(language => ! isActive(language.code))
        let list = [...enabled, ...disabled].map((language: any,index) => {

            let upButton = (
            <>
            <ListItemButton onClick={() => reorder(language.code, -1)}> 
                <FontAwesomeIcon icon={faArrowUp} />
            </ListItemButton>
            </>
            )
            let downButton = (
            <ListItemButton onClick={() => reorder(language.code, +1)}> 
                <FontAwesomeIcon icon={faArrowDown} />
            </ListItemButton>
            )

            let active = isActive(language.code)
            let backgroundColor = active ? "green" : "grey"

            return (

            <ListItem sx={[
                {
                    backgroundColor: 'light' + backgroundColor
                },
                isActive(language.code) && {
                    '&:hover': { backgroundColor: backgroundColor }
                } || {
                    '&:hover': { backgroundColor: backgroundColor }
                }
            ]} 
            key={index} > 

           {active ? upButton : ""} 
                <ListItemText style={{textAlign: "center", userSelect: "none"}} onClick={() => toggleLanguage(language.code)}>
                    {/* currently need to click on the text on the language div, change to a button? */}
                    {language.name }
                </ListItemText>
            {active ? downButton : ""}

            </ListItem>

            )
        })
        return (
            <>
            <List>
                {list}
            </List>
            </>
        )

    }

    const reorder = (language:string, direction: number) => {
        let arr = props.config.translationLanguages
        let pos = arr.indexOf(language)
        let result = pos + direction
        let prev = arr[result]

        if (result === -1 || result === arr.length) {
            return
        }

        arr[result] = language
        arr[pos] = prev

        props.config.translationLanguages = arr
        props.handler(props.config)

    }

    return (
        <>
        {languagePicker()}
        </>
        )
    }

}

export default LanguagePicker