import React, { useState, useEffect} from 'react'

interface confProps {
    config: string,
    //handler: () => {}
    handler: any,
    isValidJSON: any
}

const JsonEditor: React.FC<confProps> = (props) => {


    return ( 
        <>
        <textarea spellCheck={false} rows={10} cols={100} 
        onChange={(e) => props.handler(e.target.value) } value={props.config} 
        ></textarea>
        { props.config ? `JSON seems to be ${props.isValidJSON ? "valid" : "broken"}` : "" }
        </>
    )
}

export default JsonEditor