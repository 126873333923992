import axios from 'axios'

export const isDev = () => process.env.NODE_ENV === 'development'

const axiosInstance = axios.create({
    baseURL: isDev() ? process.env.REACT_APP_DEV_API : process.env.REACT_APP_PROD_API,
    headers: { 
        'withCredentials': true, 
        //'Content-Type': 'application/json',
    }
})

export default axiosInstance