import React, { useState } from 'react'

import supportedLanguages from '../../supportedLanguages';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faCheck, faClose, faEdit, faPen, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { List, ListItem, ListItemText, Box, IconButton, NativeSelect, TextField, Dialog, DialogActions, DialogContent } from '@mui/material'
import { EditorPlusButton } from './EditorPlusButton';
import { frontEndConfigurationMapperToModel } from '../../util/frontEndConfigurationMapperToModel';

const unique = (arr:any[])=>arr.filter((v,i,a)=>a.indexOf(v)==i)

//autofocus still broken? https://github.com/mui/material-ui/issues/33004
//^Add autofocus to dialog textfield if fixed, or it might start working on its own

const TranslationsEditor = (props: any) => {
    const [selectedLanguage, setSelectedLanguage] = useState<string>("Global")
    const [dialogOpen, setDialogOpen] = useState(false)

    const [oldValue, setOldValue] = useState("")
    const [newValue, setNewValue] = useState({
        key: "",
        value: ""
    })

    /*
    handles (global)translations and (global)postprocess fields in customTranslationsData
    field = postprocess || translations
    */
    const field = props.field
    const globalField = "global" + props.field[0].toUpperCase() + props.field.slice(1)

    const translationLanguageList = [].concat(
        props.config.translationLanguages ? props.config.translationLanguages:[],
        (props.config.dynastyConfigurations && props.config.dynastyConfigurations.translationLanguages) ?
            props.config.dynastyConfigurations.translationLanguages:[])
    
    const userLanguages = [
        {name: "Global",code:"Global"}, 
        ...unique(translationLanguageList)
            .map((languageCode: string) => 
                supportedLanguages.find(lang => lang.code === languageCode))]

    const createLanguageSelect = () => {

        const options = userLanguages
            .map((language: any, index: number) => { 
                return <option 
                            key={index} 
                            value={language.code}>
                            {language.name.includes(" ") ? 
                                language.name.split(" ").slice(-1):
                                language.name}
                        </option> }) 

        return (
            <>
            <NativeSelect 
                fullWidth 
                sx={{
                    marginTop:"20px",
                    background: props.colorScheme ? props.colorScheme:"#A079F2",
                    color: "white",    
                    fontSize: "14px",
                    fontWeight:700,
                    marginBottom:"50px",
                }}
                inputProps={{
                    style:{textAlign: "center"},
                }}
                onChange={(e) => handleLanguageSelect(e.target.value)}>
                {options}
            </NativeSelect>
            </>
        )

    }

    const handleLanguageSelect = (value: string) => {
        setSelectedLanguage(value)
    }

    const handleGlobalDelete = (word: string) => {
        delete props.config.customTranslationsData[frontEndConfigurationMapperToModel(globalField)][word]
        props.handler(props.config)
    }

    const handleDelete = (language: string, word: string) => {
        delete props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][language][word]
        props.handler(props.config)
    }

    const handleAdd = () => {
        setDialogOpen(false)

        if (selectedLanguage === "Global") {
            props.config.customTranslationsData[frontEndConfigurationMapperToModel(globalField)][newValue.key] = newValue.value
        }
        else {
            if (! props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][selectedLanguage]) {
                props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][selectedLanguage] = {}
            }
            props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][selectedLanguage][newValue.key] = newValue.value
        }
        props.handler(props.config)
        setNewValue({key: "", value: ""})
    }

    const handleEdit = () => {
        setDialogOpen(false)
        if (selectedLanguage === "Global") {
            props.config.customTranslationsData[frontEndConfigurationMapperToModel(globalField)][newValue.key] = newValue.value
            if (newValue.key !== oldValue) {
                delete props.config.customTranslationsData[frontEndConfigurationMapperToModel(globalField)][oldValue]
            }
        }
        else {
            props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][selectedLanguage][newValue.key] = newValue.value
            if (newValue.key !== oldValue) {
                delete props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][selectedLanguage][oldValue]
            }
        }

        props.handler(props.config)
        setNewValue({key: "", value: ""})

    }

    const handleSaveDialog = () => {
        if (oldValue === "") {
            handleAdd()
        }
        else {
            handleEdit()
        }
    }

    const openEdit = (key: string, value: string) => {
        setOldValue(key)
        setNewValue({
            key:key,
            value:value
        })
        setDialogOpen(true)
    }

    const createRow = (obj:any,index:number,editClick:any,deleteClick:any)=>(
        <ListItem sx={{paddingLeft:0,paddingRight:0,maxWidth:"95%"}} key={index}>
             <TextField 
                size="small" 
                label={"From"} 
                style={{width:"95%",marginRight:"15px"}} 
                value={obj[0]} 
                InputProps={{
                    readOnly: true,
                }}
                variant="standard"
                /> 
                
            {/* <ListItemText style={{width:"95%",marginRight:"15px"}} >{obj[0]}</ListItemText> */}
            <IconButton disabled aria-label="arrow" size="small" >
                <FontAwesomeIcon icon={faArrowRight}/>
            </IconButton>
            <TextField 
                size="small" 
                label={"To"} 
                style={{width:"95%",marginLeft:"15px"}} 
                value={obj[1]} 
                InputProps={{
                    readOnly: true,
                  }}
                variant="standard"
                />
            {/* <ListItemText>{obj[1]}</ListItemText> */}
            <IconButton aria-label="edit" size="small" onClick={editClick} >
                <FontAwesomeIcon icon={faPen}/>
            </IconButton> 
            <IconButton aria-label="delete" size="small"  onClick={deleteClick}> 
                <FontAwesomeIcon icon={faTrash}/>
            </IconButton>
        </ListItem>
    )

    const createGlobalRows = () => {
        if (! props.config.customTranslationsData[frontEndConfigurationMapperToModel(globalField)]) return

        let items = 
            Object.entries(props.config.customTranslationsData[frontEndConfigurationMapperToModel(globalField)])
                .map((obj,index)=>
                    createRow(
                        obj,
                        index,
                        ()=>openEdit(obj[0], obj[1] as string),
                        () => handleGlobalDelete(obj[0])))
            return (
                <>
                    <Box sx={{/*maxWidth: 360,*/ maxHeight: 600, overflow: 'auto',marginTop:"45px"}}>
                        <List >
                            {items}
                        </List>
                    </Box>
                </>
                )
    }
    
    const createRows = (language: string) => {
        if (! props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][`${language}`]) return

        let items = 
            Object.entries(props.config.customTranslationsData[frontEndConfigurationMapperToModel(field)][`${language}`])
                .map((obj: any, index: number) => 
                    createRow(
                        obj,
                        index,
                        () => openEdit(obj[0], obj[1]),
                        () => handleDelete(language,obj[0])))
        return (
            <>
                <Box sx={{/*maxWidth: 360, */maxHeight: 600, overflow: 'auto',marginTop:"45px"}}>
                    <List >
                        {items}
                    </List>
                </Box>
            </>
            )
}

    return (
        <>
        <span style={{color:"#111"}}>Editing now </span>
        {createLanguageSelect()}
        <EditorPlusButton onClick={() => setDialogOpen(true)} backgroundColor={props.colorScheme}/>

        <Dialog 
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onKeyUp={(e) => {if (e.key === "Enter") handleSaveDialog()}}
        >
            <DialogContent>
                <TextField label="From" value={newValue.key} autoFocus={true} onChange={ (e) => setNewValue({...newValue, key: e.target.value }) } />
                <TextField label="To" value={newValue.value} onChange={ (e) => setNewValue({...newValue, value: e.target.value }) } />
            </DialogContent>
            <DialogActions>
                <IconButton  onClick={() => setDialogOpen(false)}>
                    <FontAwesomeIcon icon={faClose}/>
                </IconButton>
                <IconButton onClick={handleSaveDialog}>
                    <FontAwesomeIcon icon={faCheck}/>
                </IconButton>
            </DialogActions>
        </Dialog>

        {selectedLanguage === "Global" ? createGlobalRows() : createRows(selectedLanguage)}
        </>
    )
}

export default TranslationsEditor