import React, { useState, useEffect} from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import { List, ListItem, IconButton, TextField, Button, Box } from '@mui/material'
import { EditorPlusButton } from './EditorPlusButton'

const LockedWordsEditor = (props:any) => {

    const handleLockedWordsChange = (text: string, index: number) => {
        props.config.customTranslationsData.lockedWords[index] = text
        props.handler(props.config)
    }

    const handleAddLockedWords = () => {
        props.config.customTranslationsData.lockedWords = [...props.config.customTranslationsData.lockedWords, ""]
        props.handler(props.config)
    }

    const handleDelete = (index: number) => {
        props.config.customTranslationsData.lockedWords.splice(index, 1)
        props.handler(props.config)
    }

    const createLockedWords = () => {
            let items = props.config.customTranslationsData.lockedWords.map((word: string, index: number) => {
            return (
                <ListItem sx={{paddingLeft:0,paddingRight:0,borderRadius:16}} key={index}>
                    <TextField fullWidth value={word} style={{borderRadius:16}} onChange={(e) => handleLockedWordsChange(e.target.value, index)} />
                    <IconButton aria-label="delete" onClick={() => handleDelete(index)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </IconButton>
                </ListItem>
            )
            }).reverse() //print newest on top so that it stays close to the add button

            return (
                <>
                <Box sx={{/*maxWidth: 360,*/ maxHeight: 600, overflow: 'auto'}}>
                    
                    <EditorPlusButton onClick={handleAddLockedWords} backgroundColor={props.colorScheme}/>
                    <List style={{marginTop:"45px"}}>
                        {items}
                    </List>
                </Box>
                </>
                )
    }

    return (
        <>
         {createLockedWords()}
        </>
    )
}

export default LockedWordsEditor