import { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';

  //type Severity = "error" | "success" | "info" | "warning" | undefined;
interface alertProps {
    type: string,
    message: string,
    open: boolean,
    handler: any,
    duration: number
}

const defaultProps: alertProps = {
  type: "success",
  message: "Success",
  open: true,
  handler: () => {},
  duration: 2000
}

const CustomAlert: React.FC<alertProps> = (props) => {

    const type = props.type as AlertColor

    const handleClose = () => {
        props.handler()
    }

    return (
        <>
        <Snackbar open={props.open} message={props.message} onClose={() => handleClose()} autoHideDuration={2000} >
          <Alert severity={type} >
            {props.message}
          </Alert>
        </Snackbar>
        </>
    )

}


export default CustomAlert
  