import React, { useState, useEffect} from 'react'

import TranslationsEditor from './config/TranslationsEditor';
import LockedWordsEditor from './config/LockedWordsEditor';

import supportedLanguages from '../supportedLanguages';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

interface confProps {
    config: any,
    handler: any
    // displayStraight?:boolean,
    viewToDisplayStraight?:string,
    hideMenus?:boolean
}

const CustomTranslationsEditor: React.FC<confProps> = (props) => {
    const [tab, setTab] = useState(props.viewToDisplayStraight ? props.viewToDisplayStraight:"")
    const fields = ["Locked Words", "Replacements", "Postprocess"]
    const languages = ["Global", "en", "fi", "ee", "nor"]
    const supported = Object.entries(supportedLanguages)
    // console.log(supported)

    // const [dialogOpen, setDialogOpen] = useState(false)
    // const [dialog, setDialog] = useState({
        // title: "",
        // instructions: "",
        // label: "",
        // target: "",
        // index: 0
    // })

    useEffect(() => {
        if (! props.config.customTranslationsData ) {
            props.config.customTranslationsData = { lockedWords: [], globalTranslations: {}, translations: {}, globalPostprocess: {}, postprocess: {} }
            props.handler(props.config) 
        }
    }, [])
    

    const handleTabChange = (field: string) => {
        setTab(field)
    }

    const createTabsSelect = () => {
        let divs = fields.map((field:string, index) => {
            return (
                <Button key={index} onClick={() => handleTabChange(field)} >{field}</Button>
            )
        })
        return(
                <Box sx={{width: 600, height: 100}}>
                <ButtonGroup >
                    {divs}
                </ButtonGroup>
                </Box>
        )
    }

    const handleSelect = (field: string) => {
        switch(field) {
            case "Locked Words":
                return <LockedWordsEditor config={props.config} handler={props.handler} />
            case "Replacements":
                return <TranslationsEditor field={"Replacements"} config={props.config} handler={props.handler} languages={languages} />
            case "Postprocess":
                return <TranslationsEditor field={"Postprocess"} config={props.config} handler={props.handler} languages={languages} />
        }
    }

    if (! props.config.customTranslationsData) { 
        return ( <> loading </>) 
    }
    else { 
        return (
        <>
        {!props.hideMenus?createTabsSelect():null}
        <h1>{tab}</h1>
        {handleSelect(tab)}
        </>
    )
}

}

export default CustomTranslationsEditor