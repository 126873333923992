import React, { useState, useEffect} from 'react'

import TranslationsEditor from './config/TranslationsEditor';
import LockedWordsEditor from './config/LockedWordsEditor';

import supportedLanguages from '../supportedLanguages';
import { TranslationsInfo } from './TranslationsInfo';


interface confProps {
    config: any,
    handler: any
    // displayStraight?:boolean,
    viewToDisplayStraight:string,
    hideMenus?:boolean,
    colorScheme?:string
}

export const CustomTranslationsEditorView: React.FC<confProps> = (props) => {
    const tab = props.viewToDisplayStraight
    const fields = ["Locked Words", "Replacements", "Postprocess"]
    const languages = ["Global", "en", "fi", "ee", "nor"]
    const supported = Object.entries(supportedLanguages)
    // console.log(supported)

    // const [dialogOpen, setDialogOpen] = useState(false)
    // const [dialog, setDialog] = useState({
        // title: "",
        // instructions: "",
        // label: "",
        // target: "",
        // index: 0
    // })

    useEffect(() => {
        if (! props.config.customTranslationsData ) {
            props.config.customTranslationsData = { lockedWords: [], globalTranslations: {}, translations: {}, globalPostprocess: {}, postprocess: {} }
            props.handler(props.config) 
        }
    }, [])
    

    

    
    const handleSelect = (field: string) => {
        switch(field) {
            case "Locked Words":
                return <LockedWordsEditor config={props.config} colorScheme={props.colorScheme} handler={props.handler} />
            case "Replacements":
                return <TranslationsEditor field={"Replacements"} config={props.config} colorScheme={props.colorScheme} handler={props.handler} languages={languages} />
            case "Postprocess":
                return <TranslationsEditor field={"Postprocess"} config={props.config} colorScheme={props.colorScheme} handler={props.handler} languages={languages} />
        }
    }

    if (! props.config.customTranslationsData) { 
        return ( <> loading </>) 
    }
    else { 
        return (
        <>
            <h1 style={{fontWeight:700,fontSize:36}}>{tab}</h1>
            <TranslationsInfo tab={tab} />
            {handleSelect(tab)}
        </>
    )
    }
}

