import React, { useState, useEffect} from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Bar } from 'react-chartjs-2';

interface telemetryProps {
    // config: string,
    rawTTSData:rawTTSDataI[],
    setRawTTSData:Function,
    rawTranslateData:rawTTSDataI[],
    setRawTranslateData:Function,
    blobName:string

}

interface rawTTSDataI {
    customer:string,
    date:string,
    amountOfListens:string
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  
  const TTSOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: "TTS usage per month",
      },
    },
  };

  const translateOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: "Translate usage per month",
      },
    },
  };

const handleRawTTSData = (data:any[],label:string) => {
    const orderedData = data.sort((a,b)=>a.date.localeCompare(b.date))
    return {
        labels:orderedData.map(item=>item.date),
        datasets:[
            {
                label:label,
                data:orderedData.map(item=>item.amountOfListens)
            }
        ]

    }
}
const handleRawTranslateData = (data:any[],label:string) => {
    const orderedData = data.sort((a,b)=>a.date.localeCompare(b.date))
    return {
        labels:orderedData.map(item=>item.date),
        datasets:[
            {
                label:label,
                data:orderedData.map(item=>item.amountOfTranslates)
            }
        ]

    }
}

const Telemetry = (props:telemetryProps) => {
    
    if(!props.rawTTSData || !props.rawTTSData.length){
        fetch(
            'https://telemetry-api-dev.voiceintuitive.com/customerData/'+props.blobName.replace(".json",""),
            {headers:{"x-api-key":"1234567890"}})
            .then(result=>result.json())
            .then(json=>props.setRawTTSData(json))
        fetch(
            'https://telemetry-api-dev.voiceintuitive.com/customerDataTranslate/'+props.blobName.replace(".json",""),
            {headers:{"x-api-key":"1234567890"}})
            .then(result=>result.json())
            .then(json=>props.setRawTranslateData(json))
        return ( 
            <p>
                loading data... ⏳
            </p>
    )
    }
    else return (
        <>
           {
            props.rawTTSData ? 
                <Bar options={TTSOptions} data={handleRawTTSData(props.rawTTSData,props.blobName)} />:<></>
           }
           {
            props.rawTranslateData ? 
                <Bar options={translateOptions} data={handleRawTranslateData(props.rawTranslateData,props.blobName)}/>:<></>
           }
        </>
    )
}

export default Telemetry
