import axios from "../../constants"

export const getConfigBlobNames =(container="config",callBack:Function)=>{
    axios.post('/api/azure/containers', { containerName: container })
        .then(result => callBack(result.data))
        .catch(err => {
            console.log(err)
        })
} 

export const getConfigData =(blobName:string,containerName="config",callBack:Function)=>{

    axios.post('/api/azure/getconfig', { containerName:containerName , blobName: blobName })
    .then(result => callBack(result.data))
    .catch(err => {
        console.log(err)
    })
}

