const WORDMAP:any={
    "Locked Words":"lockedWords",
    "Replacements":"translations",
    "Postprocess":"postprocess",
    "globalReplacements":"globalTranslations",
    "globalPostprocess":"globalPostprocess"
    
}

export const frontEndConfigurationMapperToModel=(word:string):string=>WORDMAP[word]
    
