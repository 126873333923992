const supportedLanguages = [
    {
      name: '\ud83c\uddec\ud83c\udde7 English',
      code: 'en'
    },
    {
      name: '\ud83c\uddeb\ud83c\uddee Finnish',
      code: 'fi'
    },
    {
      name: '\ud83c\uddf8\ud83c\uddea Swedish',
      code: 'sv'
    },
    {
      name: '\ud83c\uddf8\ud83c\udde6 Arabic',
      code: 'ar',
      rtl: true
    },
    {
      name: '\ud83c\udde8\ud83c\uddf3 Chinese',
      code: 'zh'
    },
    {
      name: '\ud83c\udde9\ud83c\uddf0 Danish',
      code: 'da'
    },
    {
      name: '\ud83c\uddf3\ud83c\uddf1 Dutch',
      code: 'nl'
    },
    {
      name: '\ud83c\uddea\ud83c\uddea Estonian',
      code: 'et'
    },
    {
      name: '\ud83c\uddeb\ud83c\uddf7 French',
      code: 'fr'
    },
    {
      name: '\ud83c\udde9\ud83c\uddea German',
      code: 'de'
    },
    {
      name: '\ud83c\uddee\ud83c\uddf3 Hindi',
      code: 'hi'
    },
    {
      name: '\ud83c\uddee\ud83c\uddf8 Icelandic',
      code: 'is'
    },
    {
      name: '\ud83c\uddef\ud83c\uddf5 Japanese',
      code: 'ja'
    },
    {
      name: '\ud83c\uddf0\ud83c\uddf7 Korean',
      code: 'ko'
    },
    {
      name: '\ud83c\uddf3\ud83c\uddf4 Norwegian',
      code: 'no'
    },
    {
      name: '\ud83c\uddf7\ud83c\uddfa Russian',
      code: 'ru'
    },
    {
      name: '\ud83c\uddea\ud83c\uddf8 Spanish',
      code: 'es'
    },
    {
      name: '\ud83c\uddfa\ud83c\udde6 Ukrainian',
      code: 'uk'
    },
    {
      name: '\ud83c\uddf8\ud83c\uddf4 Somalian',
      code: 'so'
    },
    {
      name: '\ud83c\uddee\ud83c\uddf7 Persian',
      code: 'fa',
      rtl: true
    },
    {
      name: '\ud83c\uddf9\ud83c\udded Thai',
      code: 'th'
    },
    {
      name: '\ud83c\uddf9\ud83c\uddf7 Turkish',
      code: 'tr'
    },
    {
      name: '\ud83c\uddfb\ud83c\uddf3 Vietnamese',
      code: 'vi'
    },
    {
      name: '\ud83c\udded\ud83c\uddfa Hungarian',
      code: 'hu'
    },
    {
      name: '\ud83c\uddee\ud83c\udde9 Indonesian',
      code: 'id'
    },
    {
      name: '\uD83C\uDDE6\uD83C\uDDF1 Albanian',
      code: 'sq'
    },
    {
      name: '\uD83C\uDF0D Kurdish',
      code: 'ku'
    }
  ]
  
  export default supportedLanguages