
export const TranslationsInfo = ({tab}:{tab:string})=>{
    const text:any = {

        "Locked Words":`These words will never be translated by the AI`,
        "Replacements":`Replace a word before translation by AI, decide what word AI will try to translate instead of the current word. Select a language where to replace or global selection affects all languages.`,
        "Postprocess":`Replace a word that was translated wrong with a new one, works based on language or global selection affects all languages.`
    }
    return (<p style={{fontSize:"17px"}}>
        {text[tab]}
    </p>
    )
}
