import React, { useState, useEffect } from 'react'
import axios from '../constants'
import { isDev } from '../constants'
import { Button, TextField, Grid, Box, Container } from '@mui/material'

const Login = (props: any) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [checked, setChecked] = useState(false)

    //check if user has a valid session already
    useEffect(() => {
    axios.get('/api/authtest')
    .then(r => {
        if (r.status === 200) {
            props.setUser(r.data)
        }
    })
    .catch(e => {
        setChecked(true)
        console.log(e) 
    })
    },[])

    const handleLogin = (e: any) => {
        e.preventDefault()
        axios.post('/api/login', { email: email, password: password })
        .then(r => {
            props.setUser(r.data)
        })
        .catch(e => { console.log(e) /*setState({...state, error: e.request}) */} ) 
    }

    if (checked) {
        return(
            <Container maxWidth="sm">
                <Grid container sx={{width:"80%",maxWidth:"800px",margin:"0px auto 0 auto"}} direction={"column"} rowSpacing={3}>
                    <Grid item component="img" sx={{margin:"10px auto 50px auto",width:"256px"}} src="assets/images/rainbowlogo.png"/>
                    <Grid item>
                        <TextField 
                            label="Email" 
                            onChange={(e) => setEmail(e.target.value)} 
                            // size="40"
                            fullWidth/>
                    </Grid>
                    <Grid item>
                        <TextField 
                            onKeyUp={(e) => {if (e.key === "Enter") handleLogin(e)}} 
                            type="password" 
                            label="Password" 
                            onChange={(e) => setPassword(e.target.value)} 
                            fullWidth
                            />
                    </Grid>
                    <Grid item textAlign="center">
                        <Button 
                            sx={{width:"100%",fontWeight:700}}
                            color="primary"
                            onClick={handleLogin} 
                            variant="contained">Login</Button>
                    </Grid>
                </Grid>
            </Container>
        )
    }
    else {
        return(
            <>
            Loadering.gif
            </>
        )
    }
}

    export default Login