import { Chip, Paper } from "@mui/material";

export const FileInfoBox = (
        {fileName,backgroundColor=""}:{fileName:string,backgroundColor?:string})=>(
    <Paper 
        style={{
            background:backgroundColor,
            padding:"17px",
            width:"620px",
            borderRadius: "16px",
            marginBottom:"25px"}}>
        <Chip 
            label="Main site" 
            color="primary"
            size="small"
            sx={{marginLeft:"20px",boxShadow:2,fontWeight:700,marginTop:-1}}
            />

        <span style={{marginLeft:"40px"}}><span style={{fontWeight:700,fontSize:20}}>{fileName}</span></span>
        <span style={{float:"right",marginRight:"10px",color:"#333"}}>Editing now </span>
    </Paper>
    )
