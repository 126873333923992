import { useState } from 'react';

import axios from '../constants'

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';


const Purge = (props: any) =>  {
    const [response, setResponse] = useState("")

    const handlePurge = () => {
        if (! props.selected.blobName) {
            props.alertHandler("Select file", "error", 3000)
            return
        }
        axios.post('/api/purge/', { contentPaths: [props.selected.blobName] })
        .then(r => {
            setResponse(r.data)
            props.alertHandler("Done", "success", 3000)
        })
        .catch(e => { 
            setResponse(e.response.data)
            props.alertHandler("Error", "error", 3000)
        }) 
    }

//    const handlePurgeAll = () => {
//        axios.post('/api/purge/', { contentPaths: ["*"] })
//        .then(r => {
//            setResponse(r.data)
//            props.alertHandler("Done", "success", 3000)
//        })
//        .catch(e => { 
//            setResponse(e.response.data)
//            props.alertHandler("Error", "error", 3000)
//        }) 
//    }

    return(
        <>
        <br/>
        <p> /config folder hardcoded on server side. </p> 
        <p> After sending req wait ~10s for result to show up as a info text box. </p>
        <p> Receiving updated config from frontdoor might take up to 10min according to microsoft, actual time was more like ~1min in testing. </p>
        <p> Note: Purge functionality only works when hosted on azure web app(probably) (using app managed identity) </p>
        <Box>
            <ButtonGroup>
                <Button onClick={() => handlePurge()} >
                    Purge selected file 
                </Button>
                {/* <Button onClick={() => handlePurgeAll()}> */}
                    {/* Nuke everything (experimental) */}
                {/* </Button> */}
            </ButtonGroup>
        </Box>
        <br/>
        {response ? 
        <textarea readOnly value={JSON.stringify(response)} ></textarea>
        : ""}
        </>
    )
}

export default Purge