import React, { useState, useEffect} from 'react'
import axios from '../constants'

import Purge from './Purge'
import LanguagePicker from './LanguagePicker'
import JsonEditor from './JsonEditor'
import CustomTranslationsEditor from './CustomTranslationsEditor'

import Button from '@mui/material/Button';
import { Grid} from '@mui/material'
import { CustomTranslationsEditorView } from './CustomTranslationsEditorView'
import { FileInfoBox } from './FileInfoBox'
import handlePurge from '../util/handlePurge'
import Telemetry from './Telemetry'

interface configProps {
    user: any,
    alertHandler: any,
    render: string,
    loadBlobAutomatically?:boolean,
    selectedConfig?:string,
    colorScheme?:string
}

const ConfigEditor: React.FC<configProps> = (props) => { 
    const [blobContainers] = useState<string[]>(["config", "config-dev", "testcontainer"])
    const [blobs, setBlobs] = useState<string[]>([])
    const [original, setOriginal] = useState<any>()
    const [modified, setModified] = useState<string>("{}")
    const [isValidJSON, setIsValidJSON] = useState<boolean>(true)
    const [telemetryTTSData,setTelemetryTTSData] = useState<any[]>([])
    const [telemetryTranslateData,setTelemetryTranslateData] = useState<any[]>([])
    const [selected, setSelected] = useState<any>({
        containerName: "config",
        blobName: props.selectedConfig ? props.selectedConfig:""
    })
    const [newFile, setNewFile] = useState(false)

    useEffect(() => {
        if (blobs.length === 0) {
            handleContainerSelect("config")
        }
    })

    const handleContainerSelect = (value: string) => {
        axios.post('/api/azure/containers', { containerName: value })
        .then(result => {
            let temp = ["Select file", "Create new file"]
            setBlobs(temp.concat(result.data))
            setSelected({
                containerName: value,
                blobName: ""
            })
            if(props.loadBlobAutomatically){
                console.log("setting selected:",selected.blobName ? selected.blobName: result.data[0])
                handleBlobSelect(selected.blobName ? selected.blobName: result.data[0])
            }
  
        })
        .catch(err => {
            console.log(err)
        })
    }

    const handleBlobSelect = (value: string) => {
        if (value === "Create new file") {
            selected.blobName = ""
            setSelected(selected)
            setNewFile(true)
            setModified(
                JSON.stringify({}, null, 2)
            )
        }

        if (value !== "Select file" && value !== "Create new file") {
            setNewFile(false)
            axios.post('/api/azure/getconfig', { containerName: selected.containerName, blobName: value })
            .then(result => {
                //console.log(result)
                setOriginal(result.data)
                // ^implement diff?
                setModified(JSON.stringify(result.data, null, 2))
                setSelected({
                    containerName: selected.containerName,
                    blobName: value
                })
            })
            .catch(err => {
                console.log(err)
            })
        }

    }

    const createContainerSelect = () => {
        let options: any[] = blobContainers.map((container: string, index) => { return <option key={index} value={container}>{container}</option> }) 
        if(props.loadBlobAutomatically) return (<></>)
        return (
            <>
            <select onChange={(e) => handleContainerSelect(e.target.value)}>
                {options}
            </select>
            </>
        )

    }

    const createBlobSelect = (arr: string[]) => {
        if (blobs.length === 0 ) {
            return ""
        }
        if(props.loadBlobAutomatically) return (<></>)
        let options: any[] = arr.map((blob: string, index) => { return <option key={index} value={blob}>{blob}</option> }) 
        return (
            <>
            <select onChange={(e) => handleBlobSelect(e.target.value)}>
                {options}
            </select>
            </>
        )
    }

    const validateJSON = (str: string) => {
        try {
            JSON.parse(str)
        }
        catch(err) {
            return false
        }
        return true

    }
    
    const handleConfigChangesObj = (e: any) => {
        //some editors take config as obj instead of string
        let jsonText = JSON.stringify(e, null, 2)
        setIsValidJSON(validateJSON(jsonText) ? true : false)
        setModified(jsonText)
    }

    const handleConfigChanges = (e: string) => {
        setIsValidJSON(validateJSON(e) ? true : false)
        setModified(e)

    }

const handleSave = () => {
    if (isValidJSON) {
        if (selected.blobName.length > 0 && selected.containerName.length > 0) {
            axios.put('/api/azure/config', { containerName: selected.containerName, blobName: selected.blobName, json: modified})
            .then(res => {
                props.alertHandler("Saved", "success", 3000)
                console.log("saving succeeded, starting to clear cache.")
                handlePurge(
                    selected.blobName,
                    ()=>props.alertHandler("Cache purged", "success", 3000),
                    ()=>props.alertHandler("Cache purge failed", "error", 3000));
            })
            .catch(err => {
                props.alertHandler("Error", "error", 3000)
            })
        }

    }
}

const createNewFileInput = () => {
    if (newFile) {
        return (
        <>
        <br/> 
        Filename: 
        <input type="text" value={selected.blobName}
        onChange={(e) => setSelected({containerName: selected.containerName, blobName: e.target.value }) } />
        </>
        )
    }
}

const renderSelectedEditor = (render:string) => {
    console.log({render})
    switch(render) {
        case 'Telemetry':
            return<Telemetry 
                rawTTSData={telemetryTTSData} 
                setRawTTSData={setTelemetryTTSData} 
                rawTranslateData={telemetryTranslateData}
                setRawTranslateData={setTelemetryTranslateData}
                blobName={selected.blobName}/>
        case 'JsonEditor': 
            return<JsonEditor config={modified} isValidJSON={isValidJSON} handler={handleConfigChanges} />
        case 'Purge':
            return<Purge alertHandler={props.alertHandler} selected={selected} />
        case 'LanguageEditor':
            return <LanguagePicker config={JSON.parse(modified)} handler={handleConfigChangesObj} />
        case 'CustomTranslationsEditor':
            return <CustomTranslationsEditor config={JSON.parse(modified)} handler={handleConfigChangesObj} />
        case 'Locked Words':
            return <CustomTranslationsEditorView 
                hideMenus={true} 
                viewToDisplayStraight={render} 
                config={JSON.parse(modified)} 
                colorScheme={props.colorScheme}
                handler={handleConfigChangesObj} />   
        case 'Postprocess':
            return <CustomTranslationsEditorView 
                hideMenus={true} 
                viewToDisplayStraight={render} 
                config={JSON.parse(modified)} 
                colorScheme={props.colorScheme}
                handler={handleConfigChangesObj} />   
        case 'Replacements':
            return <CustomTranslationsEditorView 
                hideMenus={true} 
                viewToDisplayStraight={render} 
                config={JSON.parse(modified)} 
                colorScheme={props.colorScheme}
                handler={handleConfigChangesObj} />   
        default: 
           return  ""
    }
}

return( 
    <Grid 
        container 
        style={{paddingLeft:"155px",width:"800px",marginTop:"64px"}} 
        alignItems={"start"} 
        direction={"column"}>
        <Grid item >
            <FileInfoBox fileName={selected.blobName} backgroundColor={props.colorScheme ? props.colorScheme:"#A079F2"}/>
        </Grid>
        <Grid item >
            {props.user.flags[0] === "1" ? createContainerSelect() : null }
        </Grid>
        <Grid item>
            { blobs.length > 0 ? createBlobSelect(blobs) : "" } 
        </Grid>
        <Grid item>
            {createNewFileInput()}
        </Grid>
        <Grid width={"620px"} item>
            {renderSelectedEditor(props.render) }
            { (isValidJSON && 
                props.render !== "Telemetry" && 
                props.render !== "Purge") ? 
                <Button 
                    variant="contained" 
                    style={{
                        float:"right",
                        margin:"20px",
                        marginTop:"50px",
                        width:120,
                        fontWeight:700,
                        backgroundColor:props.colorScheme?props.colorScheme:"#A079F2"}} 
                    onClick={() => handleSave()} >Apply</Button> : 
                ""}
        </Grid>
    </Grid>
)

}

export default ConfigEditor