import { useEffect, useState } from 'react';

import { Box, Drawer, List, ListItem, ListItemButton, ListItemText,Button, Grid} from '@mui/material'
import axios from 'axios';

type SideBarProps = {
    flags:string,
    handler:Function,
    selectedItem:string,
    setSelectedItem:Function
}

const SideBar = (props: SideBarProps) => {
    
    //"user.flags" are used to determine access and selectively render navigation
    //000 <- admin/tts/translate

    const handleLogout = (e: any) => {
        e.preventDefault()
        axios.post('/api/logout')
            .then(e=>window.location.reload())
            .catch(e => { console.log("ALERT!",{e})} )
        
    }

    const userNav = { 
        divider: "", 
        handler: props.handler, 
        components: ["Choose Site"] }
    const adminNav = { 
        divider: "Admin", 
        handler: props.handler, 
        components: ["JsonEditor", "Purge", "Telemetry"] }
    const textToSpeechNav = { 
        divider: "Speech settings", 
        handler: props.handler, 
        components: [] }
    const translateNav = { 
        divider: "Custom Translations", 
        handler: props.handler, 
        components: ["Locked Words", "Replacements","Postprocess"] }

    const createNavGroup = (data: any) => {
        const divs = data.components.map((item: any, index: any) => {
            return (
                    <ListItem 
                        style={{margin:"10px"}} 
                        selected={props.selectedItem===item ? true:false} 
                        key={index} 
                        disablePadding >
                        <ListItemButton 
                            style={{padding:"1px"}} 
                            onClick={() => {
                                data.handler(item);props.setSelectedItem(item)}}>
                            <ListItemText 
                                primary={item} 
                                primaryTypographyProps={{
                                    style:{
                                        // background:props.selectedItem===item ? "rgba(17, 17, 17, 0.08)":"",
                                        width:"90%",
                                        fontSize:"20px",
                                        textAlign:"center",
                                        fontWeight:item==="Choose Site" ? 500:300,
                                        borderRadius:"7px"}}}/>
                        </ListItemButton>
                    </ListItem> 
                    )
                }
                )
                return (
                    <Grid item>
                <List>
                    <ListItem disablePadding >
                        <ListItemText 
                            primary={data.divider ? (data.divider+" ⌄"):""}
                            primaryTypographyProps={{
                                style:{
                                    fontWeight:500,
                                    textAlign:"center",
                                    fontSize:"20px",
                                    
                                }
                            }} />
                    </ListItem>
                    {divs}
                </List>
            </Grid>
            
        )
    }

    return (
        <Box>
            <Drawer variant='permanent' PaperProps={{sx:{width:"334px",border:"none"}}} sx={{}}>
                <Grid container direction={"column"} alignItems="center" width="xs">
                    <img 
                        src="assets/images/rainbowlogo.png"
                        style={{marginBottom:"69px",width:"100%",maxWidth:"244px",marginTop:"25px"}}
                        />
                        {createNavGroup(userNav)}
                    {props.flags[0] === "1" ? createNavGroup(adminNav) : null}          
                    {props.flags[1] === "1" ? createNavGroup(textToSpeechNav) : null}          
                    {props.flags[2] === "1" ? createNavGroup(translateNav) : null}          
                    <Button
                        size="small" 
                        sx={{width:"95px",marginTop:"50px",fontWeight: 600}} 
                        onClick={handleLogout} 
                        variant="contained">Log out</Button>
                </Grid>
            </Drawer>
        </Box>
    )
}

export default SideBar