import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";


export const EditorPlusButton = ({onClick,backgroundColor="#a079f2"}:{onClick:any,backgroundColor?:string})=>(
    <IconButton
        aria-label="edit"
        size="medium" 
        sx={{ 
            color: "white", 
            backgroundColor: backgroundColor ? backgroundColor:"#a079f2",
            ":hover":{
                filter:"brightness(92%)",
                backgroundColor: backgroundColor ? backgroundColor:"#a079f2"
            },
             fontSize:"100%",
            padding: "20px",
            boxShadow:3}} 
        onClick={onClick}> 
        <FontAwesomeIcon icon={faPlus}/>
    </IconButton> 
)